// 证书查询、证书页面
import request from '@/utils/request'
export function get_imgId(params) {
  return request({
    url: 'personal/Certification/searchCertification',
    method: 'get',
    params
  })
}
export function get_img(params) {
  return request({
    url: 'personal/certification/downCertification',
    method: 'get',
    params
  })
}
export function get_infos(params) {
  return request({
    url: 'personal/Certification/getCertificationInfo',
    method: 'get',
    params
  })
}
// 证书查询列表
export function searchCertification(params) {
  return request({
    url: 'personal/Certification/searchCertification',
    method: 'get',
    params
  })
}
