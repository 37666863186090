<template>
  <div>
    <heads :more="showMore" :msg="title_msg" :states="1"></heads>
    <div class="ipt">
      <input v-model="code" type="text" placeholder="请输入您的身份证号或筑龙证书编号">
    </div>
    <!-- <div v-show="isZsbh" class="errmsg">
      <span>{{ msg }}</span>
    </div> -->
    <div class="btn">
      <button @click="myserch()">立即查询</button>
    </div>
    <div v-if="certificateList.length>1" class="searchRes">
      <p class="searchTitle">
        <span>个人证书（{{ certificateList.length }}个）</span>
        <span>操作</span>
      </p>
      <p v-for="(item,index) in certificateList" :key="index">
        <span v-if="item.certificate_name.length<16">{{ item.certificate_name }}</span>
        <span v-else>{{ item.certificate_name.substr(0,5) +'...' }}</span>
        <span class="check" @click.stop="check(item.id)">查看</span>
      </p>
    </div>

    <van-dialog v-model="isZsbh" title="提示" :message="msg" confirm-button-text="我知道了"></van-dialog>

  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_imgId, searchCertification } from '@/api/credential.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      isshow: false,
      title_msg: '筑龙证书查询系统',
      code: '',
      isZsbh: false,
      showMore: true,
      msg: '请输入有效的身份证号或证书编号',
      certificateList: []
    }
  },
  methods: {
    myserch() {
      var params = {
        code: this.code
      }
      searchCertification(params).then(res => {
        if (res.errNo === 0) {
          this.isZsbh = false
          if (res.result.length == 1) {
            this.$router.push({
              path: '/CertificateQueryLook',
              query: {
                id: res.result[0].id
              }
            })
          } else if (res.result.length > 1) {
            this.certificateList = res.result
          }
        } else {
          this.isZsbh = true
          this.msg = res.msg ? res.msg : '未查询到相关筑龙证书'
        }
      })
      // get_imgId(params).then(res => {
      //   if (res.errNo === 0) {
      //     this.$router.push({
      //       path: '/CertificateQueryLook',
      //       query: {
      //         id: res.result.id
      //       }
      //     })
      //     this.isZsbh = false
      //   } else {
      //     this.isZsbh = true
      //     this.msg = res.msg ? res.msg : '未查询到相关筑龙证书'
      //   }
      // })
    },
    // 查看
    check(id) {
      this.$router.push({
        path: '/CertificateQueryLook',
        query: {
          id: id
        }
      })
    },
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.errmsg {
  color: #ee2e2e;
  padding: 15px 0 0 35px;
}
.btn {
  width: 100%;
  margin: 30px 0 0 0;
  button {
    background: #ee2e2e;
    color: #fff;
    height: 90px;
    line-height: 90px;
    width: 92%;
    display: block;
    margin: 0 auto;
    font-size: 32px;
  }
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 58px;
  z-index: 1;
}
.ipt {
  width: 100%;
  margin: 50px 0 0 0;
  input {
    margin: 0 auto;
    display: block;
    width: 85%;
    height: 80px;
    border: 1px solid #d8d8d8;
    letter-spacing: 2px;
    padding: 0 0 0 50px;
    font-size: 26px;
  }
}
/deep/.van-dialog {
  border-radius: 6px;
}

.searchRes{
  margin:40px auto;
  width: 600px;
  .searchTitle{
    font-size: 32px;
    font-weight: 800;
  }
  p{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:40px;
    font-size: 30px;
    .check{
      width: 64px;
      text-align: center;
      color:#ee2e2e;
      cursor:pointer;
    }
  }
}
</style>
